// Find disclosures
const disclosures = document.querySelectorAll('[data-disclosure], .menu__parent') || null;


// Create a disclosure (includes scope for .menu-toggle)
const makeDisclosure = (elem, index, open, solo) => {
    const menuToggle = elem.classList.contains('menu__parent');

    open = open ? open : elem.getAttribute('data-disclosure') === 'open';
    const btn  = document.createElement('button');
    let   icon = '<svg width="18"><use href="/assets/symbols.svg#expander"></use></svg>';
    let   inner;

    if ( menuToggle ) {
        btn.setAttribute('aria-label', 'Show sub-menu');
        btn.classList.add('menu-toggle');
        inner = icon;
        solo  = '.menu .menu-toggle';
    }
    else {
        icon  = `<span class="roundel fs-none">${icon}</span>`;
        inner = `${icon} ${elem.innerHTML}`;
    }

    if ( solo ) {
        btn.setAttribute('data-solo', solo);
    }

    elem.id = elem.id ? elem.id : `disclosure-${index}`;
    btn.type = 'button';
    btn.innerHTML = inner;
    // Initialise as the opposite desired because we invoke a toggle later
    btn.setAttribute('aria-expanded', !open);

    if ( menuToggle ) {
        const subMenu = elem.querySelector('.menu__child');
        elem.classList.add('has-menu'); 
        elem.insertBefore(btn, subMenu);
    }
    else {
        elem.innerHTML = '';
        elem.appendChild(btn);
    }

    toggleDisclosure(elem);  
}


// Toggle a disclosure (inc .menu-toggle)
const toggleDisclosure = (summary, open) => {
    const btn    = summary.querySelector('[type="button"]');
    const solo   = btn.getAttribute('data-solo');
    let expanded = btn.getAttribute('aria-expanded') === 'true';

    if ( open ) {
        expanded = false;
    }

    if ( solo ) {
        updateDisclosureGroup(btn, solo);
    }

    btn.setAttribute('aria-expanded', !expanded);

    const menuToggle = btn.classList.contains('menu-toggle');

    if ( menuToggle ) {
        const label = expanded ? 'Show sub-menu' : 'Hide sub-menu';
        btn.setAttribute('aria-label', label);
    }

    updateDisclosureDetails(summary, expanded);
}


const updateDisclosureGroup = (btn, solo) => {
    const config = solo.split(' ');
    const group  = document.querySelector(config[0]) || null;
    const elems  = document.querySelectorAll(solo) || null;

    if ( group && elems ) {
        elems.forEach(function(elem) {
            if ( elem !== btn ) {
                elem.setAttribute('aria-expanded', 'false');
            }
        });
    }
}


// Update disclosure details
const updateDisclosureDetails = (summary, hide) => {
    // Select the detail
    const tag      = summary.tagName.toLowerCase();
    const selector = `#${summary.id} ~ *:not(${tag})`;
    const detail   = summary.parentElement.querySelectorAll(selector);

    detail.forEach(function(elem) {
        elem.hidden = hide;
    });
}


// Create and initialise disclosures
if ( disclosures ) {
    disclosures.forEach(function(elem, index) {
        makeDisclosure(elem, index);
    });
}


export {
    makeDisclosure,
    toggleDisclosure,
    updateDisclosureDetails
};
