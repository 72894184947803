/***** Base *****/

import {
    toggleDisclosure
} from './modules/disclosures.js';


document.addEventListener('click', function(e) {
    const trigger = e.target;

    if ( trigger.closest('[data-disclosure][id], .has-menu') ) {
        const summary = trigger.closest('[data-disclosure][id], .has-menu');
        toggleDisclosure(summary);
    }
});
